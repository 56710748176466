import { ComponentPropsWithoutRef } from "react"

export interface BaseButtonProps {
  color?: string
  textColor?: string
  variant?: "xs" | "sm" | "base" | "lg" | "xl"
}
export interface TrueButtonProps extends BaseButtonProps, ComponentPropsWithoutRef<"button"> {
  component?: "button"
}
export interface AButtonProps extends BaseButtonProps, ComponentPropsWithoutRef<"a"> {
  component: "a"
}
export type ButtonProps = TrueButtonProps | AButtonProps

export const Button = ({
  component = "button",
  color = "primary",
  textColor = "white",
  variant = "base",
  className,
  ...props
}: ButtonProps) => {
  let bgClass = color === "white" ? "bg-white" : `bg-${color}-600`
  let hoverClass = color === "white" ? "bg-gray-50" : `bg-${color}-500`
  let classes = `inline-flex items-center justify-center border border-transparent font-medium rounded shadow-sm text-${textColor} ${bgClass} hover:${hoverClass} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500 can-disable`

  switch (variant) {
    case "xs":
      classes += " px-2.5 py-1.5 text-xs leading-4"
      break
    case "sm":
      classes += " px-3 py-2 text-sm leading-4"
      break
    case "base":
      classes += " px-4 py-2 text-sm leading-5"
      break
    case "lg":
      classes += " px-4 py-2 text-base leading-6"
      break
    case "xl":
      classes += " px-6 py-3 text-base leading-6"
      break
  }

  if (className) {
    classes += " " + className
  }
  const Component = component as any

  return <Component className={classes} {...props} />
}
