import { ReactNode } from "react"
import Logo from "app/core/components/Logo"
import clsx from "clsx"

type SplitScreenLayoutProps = {
  heading: ReactNode
  children: ReactNode
  className?: any
}

export const SplitScreenLayout = ({ heading, children, className }: SplitScreenLayoutProps) => {
  return (
    <div className="flex min-h-screen bg-white">
      <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="w-full max-w-sm mx-auto lg:w-96">
          <div>
            <Logo className="w-full" />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900 font-brand leading-9">
              {heading}
            </h2>
          </div>
          <div className={clsx("mt-8", className)}>{children}</div>
        </div>
      </div>
      <div className="relative flex-1 hidden w-0 lg:block">
        <img
          className="absolute inset-0 object-cover w-full h-full"
          src="/images/smiling-girl.jpg"
          alt="pretty background"
        />
      </div>
    </div>
  )
}

export default SplitScreenLayout
