import React, { useState, useEffect } from "react"
import { LabeledTextField } from "app/core/components/LabeledTextField"
import { Form, FORM_ERROR } from "app/core/components/Form"
import login from "../mutations/login"
import { LoginInput } from "../validations"
import { Link, useMutation, AuthenticationError, useSession } from "blitz"
import SplitScreenLayout from "app/core/layouts/SplitScreenLayout"
import { GoogleOAuthProvider } from "@react-oauth/google"
import GoogleOAuth from "app/auth/components/GoogleAuth"
import { User } from "@prisma/client"
import { DialogContent, DialogOverlay } from "@reach/dialog"
import { Button } from "app/core/components/Button"
import loginNewGoogleUser from "../mutations/loginNewGoogleUser"

type LoginFormProps = {
  onSuccess?: (loginUser: User) => void
}

export const LoginForm = (props: LoginFormProps) => {
  const [loginMutation] = useMutation(login)
  const [loginNewGoogleUserMutation] = useMutation(loginNewGoogleUser)
  const session = useSession({ suspense: false })
  const [googleUser, setGoogleUser] = useState(null)
  const [errorMessage, setErrorMessage] = React.useState("")
  const [showProfilePrompt, setShowProfilePrompt] = useState(false)

  useEffect(() => {
    if (googleUser) {
      const handleLogin = async () => {
        try {
          const result = await loginMutation(googleUser)
          if (!result.profileExists) {
            setShowProfilePrompt(true)
          }
        } catch (error) {
          setErrorMessage("An error occurred during login.")
        }
      }
      handleLogin()
    }
  }, [googleUser, loginMutation])

  const onClose = () => {
    setGoogleUser(null)
    setShowProfilePrompt(false)
  }

  const onConfirm = async () => {
    await loginNewGoogleUserMutation(googleUser!)
    setShowProfilePrompt(false)
  }

  return (
    <GoogleOAuthProvider clientId={process.env.GOOGLE_OAUTH_CLIENTID || ""}>
      <SplitScreenLayout heading="Log in to your account" className="space-y-8">
        <Form
          submitText="Log In"
          schema={LoginInput}
          initialValues={{ email: session.email || undefined, password: undefined }}
          onSubmit={async (values) => {
            try {
              const loginUser = await loginMutation(values)
              if (props.onSuccess) {
                props.onSuccess(loginUser)
              }
            } catch (error) {
              if (error instanceof AuthenticationError) {
                return { [FORM_ERROR]: "Sorry, those credentials are invalid" }
              } else {
                return {
                  [FORM_ERROR]:
                    "Sorry, we had an unexpected error. Please try again. - " + error.toString(),
                }
              }
            }
          }}
        >
          <LabeledTextField
            name="email"
            label="Email"
            placeholder="Email"
            autoComplete="email"
            type="email"
          />
          <LabeledTextField
            name="password"
            label="Password"
            placeholder="Password"
            type="password"
            autoComplete="password"
          />

          <div className="flex items-center justify-end mt-6">
            <div className="text-sm leading-5">
              <Link href="/forgot-password">
                <a className="font-medium text-primary-600 hover:text-primary-500 ">
                  Forgot your password?
                </a>
              </Link>
            </div>
          </div>
        </Form>

        <GoogleOAuth setUser={setGoogleUser} setErrorMessage={setErrorMessage} />
        {errorMessage && <div className="pl-1 text-sm text-red-700">{errorMessage}</div>}

        <DialogOverlay
          isOpen={showProfilePrompt}
          onDismiss={() => setShowProfilePrompt(false)}
          className="modal--small"
        >
          <DialogContent aria-label="No account found">
            <div className="overflow-y-auto max-h-80 px-2 py-2">
              <h3 className="font-bold text-xl mb-2 pb-2 border-b border-gray-200">
                No account found
              </h3>
              <div>
                There was no account found with the Google email address. Would you like to create
                one?
              </div>
              <div className="flex justify-end space-x-4">
                <Button
                  type="button"
                  onClick={onClose}
                  className="bg-gray-300 text-black opacity-90 hover:opacity-100"
                >
                  No
                </Button>
                <Button
                  type="button"
                  onClick={onConfirm}
                  className="bg-green-500 opacity-90 hover:opacity-100"
                >
                  Yes
                </Button>
              </div>
            </div>
          </DialogContent>
        </DialogOverlay>
      </SplitScreenLayout>
    </GoogleOAuthProvider>
  )
}

export default LoginForm
