import React, { ComponentPropsWithoutRef } from "react"
import { useField, UseFieldConfig } from "react-final-form"
import { useId } from "@reach/auto-id"
import clsx from "clsx"

export interface LabeledTextFieldProps extends ComponentPropsWithoutRef<"input"> {
  /** Field name. */
  name: string
  /** Field label. */
  label: string
  /** Field type. Doesn't include radio buttons and checkboxes */
  type?: "text" | "password" | "email" | "number"
  outerProps?: ComponentPropsWithoutRef<"div">
  fieldProps?: UseFieldConfig<string>
  labelProps?: ComponentPropsWithoutRef<"label">
  hiddenLabel?: boolean
  restrictQuotes?: boolean
}

export const LabeledTextField = React.forwardRef<HTMLInputElement, LabeledTextFieldProps>(
  (
    {
      name,
      type = "text",
      label,
      outerProps,
      fieldProps,
      labelProps,
      hiddenLabel,
      className,
      restrictQuotes = false,
      onChange,
      ...props
    },
    ref
  ) => {
    const {
      input,
      meta: { touched, error, submitError, submitting },
    } = useField(name, {
      ...((type === "number"
        ? { parse: (v: string) => Number(v) }
        : {
            parse: (v: string) => (v === "" ? null : v),
          }) as any),
      ...fieldProps,
    })
    const id = useId() + name

    const normalizedError = Array.isArray(error) ? error.join(", ") : error || submitError
    const showError = touched && normalizedError

    const handleInputChange = (e) => {
      const { value } = e.target

      if (restrictQuotes && (value.includes('"') || value.includes("'"))) {
        const sanitizedValue = value.replace(/["']/g, "")
        input.onChange(sanitizedValue)
      } else if (onChange) {
        onChange(e)
      } else {
        input.onChange(value)
      }
    }

    return (
      <div {...outerProps}>
        <div className="flex justify-between text-sm font-medium leading-5">
          <label
            htmlFor={id}
            {...labelProps}
            className={clsx("text-gray-700", hiddenLabel && "sr-only", labelProps?.className)}
          >
            {label}
          </label>
          <div role="alert" className="ml-1 text-xs text-right text-red-700">
            {showError && normalizedError}
          </div>
        </div>
        <div className="mt-1">
          <input
            id={id}
            key={id}
            type={type}
            disabled={submitting}
            ref={ref}
            {...input}
            {...props}
            onChange={handleInputChange}
            className={clsx(
              "shadow-sm block w-full sm:text-sm  rounded-md",
              showError
                ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                : "focus:ring-primary-500 focus:border-primary-500 border-gray-300",
              className
            )}
          />
        </div>
      </div>
    )
  }
)

export default LabeledTextField
