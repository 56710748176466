import { GoogleLogin } from "@react-oauth/google"
import jwt_decode from "jwt-decode"
import Sentry from "integrations/sentry"

type GoogleUserType = {
  googleUser: boolean
  email: string
  firstName: string
  lastName: string
}

type LoginProps = {
  setUser: React.Dispatch<React.SetStateAction<GoogleUserType | null>>
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

const GoogleOAuth = ({ setUser, setErrorMessage }: LoginProps) => {
  const handleGoogleLoginSuccess = (response) => {
    const profile: any = jwt_decode(response.credential)
    setUser({
      googleUser: true,
      email: profile.email,
      firstName: profile.given_name,
      lastName: profile.family_name,
    })
  }
  const errorMessage = () => {
    setErrorMessage(
      "Sorry, we encountered an error while attempting to sign you in with Google. Please try again later or contact support if the issue persists."
    )
    Sentry.captureException("Error with Google Oauth Login")
  }

  return <GoogleLogin onSuccess={handleGoogleLoginSuccess} onError={errorMessage} width="384px" />
}

export default GoogleOAuth
