import React from "react"
import clsx from "clsx"

type LogoProps = {
  className?: any
}

const Logo = ({ className }: LogoProps) => (
  <a href="https://homeschoolboss.com">
    <img src="/logo.png" className={clsx("w-36", className)} alt="logo" />
  </a>
)
export default Logo
