import Sentry from "integrations/sentry"
import LogRocket from "integrations/logrocket"
import { clientEnv } from "config"

LogRocket.getSessionURL((sessionURL) => {
  Sentry.configureScope((scope) => {
    scope.setExtra("sessionURL", sessionURL)
  })
})

class Tracker {
  userId?: number
  orgId?: number
  email?: string

  setUserId(id: number) {
    try {
      if (!clientEnv.isProduction || !id) return
      if (id === this.userId) return
      this.userId = id
      Sentry.setUser({ id: id.toString() })
      LogRocket.identify(id.toString())
    } catch (error) {
      console.log("Tracking.setUserId failed", error)
    }
  }

  setOrgId(orgId: number) {
    try {
      if (!clientEnv.isProduction || !orgId) return
      if (orgId === this.orgId) return
      this.orgId = orgId
      Sentry.setUser({ orgId: orgId.toString() })
      LogRocket.identify(this.userId?.toString()! || this.email!, {
        orgId,
        email: this.email!,
      })
    } catch (error) {
      console.log("Tracking.setOrgId failed", error)
    }
  }

  setEmail(email: string) {
    try {
      if (!clientEnv.isProduction) return
      if (email === this.email) return
      this.email = email
      Sentry.setUser({ email })
      LogRocket.identify(this.userId?.toString()! || this.email!, {
        email,
        orgId: this.orgId!,
      })
    } catch (error) {
      console.log("Tracking.setEmail failed", error)
    }
  }
}

export const Tracking = new Tracker()
