import * as Sentry from "@sentry/node"
import { clientEnv } from "config/clientEnv"
import getConfig from "next/config"
import { RewriteFrames } from "@sentry/integrations"

const config = getConfig()
if (
  clientEnv.SENTRY_DSN &&
  config &&
  (typeof window === "undefined" ? true : !window.location.host?.includes("localhost"))
) {
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`
  Sentry.init({
    integrations: [
      new RewriteFrames({
        iteratee: (frame: any) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next")
          return frame
        },
      }),
    ],
    dsn: clientEnv.SENTRY_DSN,
    beforeSend(event, hint) {
      const error = hint?.originalException
      if (error && typeof error !== "string") {
        switch (error.name) {
          case "AuthenticationError":
          case "AuthorizationError":
          case "NotFoundError":
          case "BookingError":
          case "ChunkLoadError":
            return null
        }
        if (error.message?.includes("Your card was declined")) return null
        if (error.message?.includes("Your card has insufficient funds")) return null
        if (error.message?.includes("security code is incorrect")) return null
      }
      return event
    },
  })
}

export default Sentry
