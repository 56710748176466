/* eslint-disable no-process-env */
import * as e from "envsafe"

// ----------------------------------------------
// ENV: Isomorphic, included in client bundle
//   - NOTE: You must also add these vars to
//           `env` in `blitz.config.js`
// ----------------------------------------------
export const clientEnv = e.envsafe({
  APP_ENV: e.str({ input: process.env.APP_ENV }),
  isDevelopment: e.bool({ input: (process.env.APP_ENV === "development") as any }),
  isProduction: e.bool({ input: (process.env.APP_ENV === "production") as any }),
  isTest: e.bool({ input: (process.env.APP_ENV === "test") as any }),
  LOG_LEVEL: e.str({ default: "", allowEmpty: true }),
  APP_ORIGIN: e.url({
    devDefault: "http://localhost:3000",
    input: process.env.VERCEL_URL || process.env.APP_ORIGIN,
  }),
  SENTRY_DSN: e.str({ input: process.env.SENTRY_DSN, devDefault: "", allowEmpty: true }),
  STRIPE_KEY: e.str({ input: process.env.STRIPE_KEY }),
})
